<template>
	<nav class="navbar navbar-expand-lg navbar-light" id="topnav">
		<div class="container">

			<!-- Toggler -->
			<button class="navbar-toggler me-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>

			<!-- Brand -->


			<!-- Form -->


			<!-- User -->
			<div class="navbar-user">

				<!-- Dropdown -->


				<!-- Dropdown -->
				<div class="dropdown">

					<!-- Toggle -->
					<a href="#" class="avatar avatar-sm avatar-online dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<img src="@/img/dd/new.jpg" alt="..." class="avatar-img rounded-circle">
					</a>

					<!-- Menu -->
					<div class="dropdown-menu dropdown-menu-end">
						<a @click="$router.push('/change-password')" class="dropdown-item">Сменить пароль</a>
						<hr class="dropdown-divider">
						<a @click="logout" class="dropdown-item">Выход</a>
					</div>

				</div>

			</div>

			<!-- Collapse -->
			<div class="collapse navbar-collapse me-lg-auto order-lg-first" id="navbar">



				<!-- Navigation -->
				<ul class="navbar-nav me-lg-auto">
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle "  id="result" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
							Результаты
						</a>
						<ul class="dropdown-menu" aria-labelledby="result">
							<li>
								<a class="dropdown-item " @click="$router.push('/')">
									Анализы
								</a>
							</li>
							<li>
								<a class="dropdown-item " @click="$router.push('/visit')">
									Приемы специалистов
								</a>
							</li>
						</ul>
					</li>
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle "  id="schedule" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
							Записаться на прием
						</a>
						<ul class="dropdown-menu" aria-labelledby="schedule">
							<li>
								<a class="dropdown-item " @click="$router.push('/schedule/new')">
									Врачи
								</a>
							</li>
							<li>
								<a class="dropdown-item " @click="$router.push('/schedule/new-special')">
									Услуги
								</a>
							</li>
						</ul>
					</li>
					<li class="nav-item">
						<a class="nav-link"   @click="$router.push('/schedule')" >
							Мои записи
						</a>
					</li>
				</ul>

			</div>

		</div> <!-- / .container -->
	</nav>




</template>
<script>
import { Dropdown } from 'bootstrap';
export default {
	methods: {
		logout: function () {
			this.$store.dispatch('logout')
				.then(() => {
					this.$router.push('/login')
				})
		}
	},
	mounted ()
	{
		const selectors = '.navbar .dropup, .navbar .dropend, .navbar .dropdown, .navbar .dropstart';
		const dropdowns = document.querySelectorAll(selectors);
		const NAVBAR_BREAKPOINT = 767;

		if (window.innerWidth > NAVBAR_BREAKPOINT) {
			dropdowns.forEach((dropdown) => {
				const toggle = dropdown.querySelector('[data-bs-toggle="dropdown"]');
				const instance = new Dropdown(toggle);

				dropdown.addEventListener('mouseenter', () => {
					instance.show();
				});

				dropdown.addEventListener('mouseleave', () => {
					instance.hide();
				});
			});
		}
	},
}
</script>