<template>
	<lg-menu/>

	<div class="main-content">
		<div class="container">
		<!-- HEADER -->
		<div class="header">
			<div class="container-fluid">

				<!-- Body -->
				<div class="header-body">
					<div class="row align-items-end">
						<div class="col">

							<!-- Pretitle -->
							<h6 class="header-pretitle">
								Личный кабинет
							</h6>

							<!-- Title -->
							<h1 class="header-title">
								Записаться на прием
							</h1>

						</div>

					</div> <!-- / .row -->
				</div> <!-- / .header-body -->
			</div>
		</div>

		<div class="container-fluid">
			<div class="row">
				<div class="col-12 col-xl-8">
					<template v-for="staff in staff_list" >
					<!-- Card -->
						<div @click="staff_select(staff.id)" v-if="staff_id==0||staff_id==staff.id" :key="staff.id" class="card mb-3">
							<div class="card-body">
								<div class="row align-items-center">
									<div class="col-auto">

										<!-- Avatar -->
										<a  class="avatar avatar-lg">
											<template v-if="staff.photo!=''">
												<img :src="'https://api.2802801.ru/upload/resize.php?width=200&heigth=200&src=/upload/files/'+staff.photo" alt="..." class="avatar-img rounded-circle">
											</template>
											<template v-else>
												<img v-if="staff.sex=='M'" src="@/img/dd/m.jpg" class="avatar-img rounded-circle border border-4 border-card" alt="...">
												<img v-if="staff.sex=='F'" src="@/img/dd/w.jpg" class="avatar-img rounded-circle border border-4 border-card" alt="...">
											</template>
										</a>

									</div>
									<div class="col ms-n2">

										<!-- Title -->
										<h4 class="mb-1">
											{{staff.fio}}
										</h4>

										<!-- Text -->
										<p class="card-text small text-muted mb-1">
											{{staff.special}}
										</p>

										<!-- Status -->
										<p v-if="false" class="card-text small">
											<span class="text-success">●</span> Online
										</p>

									</div>
									<div class="col-auto">

										<!-- Button -->
										<a v-if="staff_id==0" class="btn btn-sm btn-primary d-none d-md-inline-block">
											Записаться
										</a>
										<div v-if="staff_id!=0"  class="col-auto" style="cursor: pointer;">
											<!-- Icon -->
											<span class="h2 fe fe-corner-right-up text-muted mb-0"></span>
										</div>

									</div>
								</div> <!-- / .row -->
							</div> <!-- / .card-body -->
						</div>
					</template>
					<div v-if="staff_id!=0" class="row">
						<template v-for="d in day_list">
							<div v-if="day==0||day==d.d" class="col-12 col-lg-6 col-xl">
								<div   class="card"  style="cursor: pointer;">
									<div @click="day_select(d.d)" class="card-body">
										<div class="row align-items-center gx-0">
											<div class="col">
												<!-- Heading -->
												<span class="h2 mb-0">{{d.title}}</span>
												<p class="card-text small text-muted mb-1">{{d.unit}}</p>
											</div>
											<div v-if="day!=0" class="col-auto">
												<!-- Icon -->
												<span class="h2 fe fe-corner-right-up text-muted mb-0"></span>
											</div>
										</div> <!-- / .row/ -->
									</div>
								</div>
							</div>
						</template>
						<div  v-if="Object.keys(day_list).length==0" class="card mb-3">
							<div class="card-body">
								<div class="row align-items-center">
									<div class="col ms-n2">
										<!-- Title -->
										<h3 class="mb-1">
											К данному специалисту ведется запись в лист ожидания. Запись возможна по номеру телефона 280-280-1.
										</h3>
									</div>
								</div> <!-- / .row -->
							</div> <!-- / .card-body -->
						</div>
					</div>
					<div v-if="day!=0" class="row">
						<template v-for="t in time_list">
							<div v-if="time==0||t.t==time" class="col-12 col-lg-6 col-xl">
								<div   class="card" style="cursor: pointer;">
									<div @click="time_select(t.t)" class="card-body">
										<div class="row align-items-center gx-0">
											<div class="col">
												<!-- Heading -->
												<h4 class="text-uppercase text-muted mb-2">{{t.title}}</h4>
											</div>
											<div v-if="time!=0" class="col-auto">
												<!-- Icon -->
												<span class="h2 fe fe-corner-right-up text-muted mb-0"></span>
											</div>
										</div> <!-- / .row/ -->
									</div>
								</div>
							</div>
						</template>
					</div>
					<div v-if="time!=0" class="card">
						<div class="card-body">
							<!-- Button toggle -->
							<div class="btn-group-toggle">
								<template v-for="(s,k,i) in service_list" :key="s.id">
									<input type="radio" class="btn-check" name="options" :value="s.id" :id="s.id" autocomplete="off" :checked="i==0"  :disabled="!s.enable" v-model="service">
									<label class="btn btn-white" :for="s.id">
										<i class="fe fe-check-circle"></i> {{s.title}} {{s.time}} мин. {{s.price}} ₽
									</label>
								</template>
							</div>
						</div>
						<div v-if="patient_list!=null" class="card-body">
							<h4 class="mb-1">Пациент</h4>
							<select v-model="patient" class="form-select mb-3" data-choices>
								<option v-for="(p,k, i) in patient_list" :value="p.id" :key="i">{{ p.fio }}</option>
							</select>
						</div>
					</div>
					<div v-if="create_visit_ok" class="alert alert-success" role="alert">

						<!-- Heading -->
						<h4 class="alert-heading">Вы успешно записаны.</h4>
						<p>
							{{service_list['id_'+service].title}}<br>
							Врач: {{staff_list['id_'+staff_id].fio}}<br>
							<template v-if="patient_list!=null">
								Пациент: {{patient_list['id_'+patient].fio}}<br>
							</template>
							Дата приёма:  {{day_list[day].title}} в {{time_list[time].title}}<br>
							Адрес:  {{day_list[day].unit}}<br>
							Стоимость приема: {{service_list['id_'+service].price}} ₽
						</p>
						<p>
							Обращаем Ваше внимание,  при первом обращении в МЦ Детский Доктор,  необходимо иметь при себе документы  для заключения договора. Совершеннолетним пациентам необходимо  иметь при себе Паспорт, пациентам до 18 лет -  Свидетельство о рождении  (если пациент не вписан в паспорт родителя). С ребенком должен быть кто-то из родителей (мама или папа).
						</p>
						<hr>

						<p class="mb-0">Если по какой-либо причине Вы не можете  сопровождать Вашего ребенка на прием к врачу  в наш медицинский центр, оформите,  пожалуйста, <a href="https://xn--d1aacvtbold.xn--p1ai/upload/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%20%D0%BF%D0%BE%D1%80%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F%20%D0%94%D0%95%D0%A2%D0%A1%D0%9A%D0%98%D0%99%20%D0%94%D0%9E%D0%9A%D0%A2%D0%9E%D0%A0.pdf" target="_blank">договор поручения</a>.</p>

					</div>
					<div v-if="create_visit_error" class="alert alert-danger alert-dismissible fade show" role="alert">
						<strong>Записаться не удалось!</strong> Попробуйте еще раз или позвоните в клинику.
					</div>
					<div v-if="time!=0" class="card-body">
						<button @click="create_visit()" type="button" class="btn btn-primary btn-lg" :disabled="!create_visit_button_active">Записаться</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>
</template>
<script>
import axios from 'axios';
import config from '@/config.js'
import LgMenu from '@/components/Menu.vue'

export default {
	components: {
		LgMenu
	},
	data() {
		return {
			staff_list: null,
			staff_id: 0,
			day_list: null,
			day: 0,
			time_list: null,
			time: 0,
			service_list: null,
			service: 0,
			patient_list: null,
			patient: 0,
			create_visit_button_active: true,
			create_visit_ok: false,
			create_visit_error: false,
			site_staff:  localStorage.getItem('schedule_site_staff') || '',
			site_d:  localStorage.getItem('schedule_site_d') || '',
			site_t:  localStorage.getItem('schedule_site_t') || '',
		}
	},
	mounted ()
	{
		this.load()
	},
	methods: {
		async load()
		{
			axios({url: config.API_LOCATION+'lk/schedule/staff_list/' })
				.then(resp => {
					this.staff_list=resp.data.data
					if(!!this.site_staff)
						this.staff_select(this.site_staff)
				})
				.catch(err => {
					console.log(err)
				})
		},
		async staff_select(staff_id)
		{
			this.create_visit_error = false
			this.create_visit_ok = false
			if(staff_id==this.staff_id){
				this.staff_id=0
				this.day=0
				this.time=0
				this.service_list=null
				this.service=0
				this.patient_list=null
				this.patient=0
				return
			}
			axios({url: config.API_LOCATION+'lk/schedule/staff_day_list/'+staff_id+'/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.day_list = resp.data.data
						this.create_visit_button_active = true
						this.staff_id=staff_id
						if(!!this.site_d)
							this.day_select(this.site_d)
						else{
							if(!!this.site_staff){
								this.site_staff=''
								localStorage.removeItem('schedule_site_staff')
							}
						}
					} else {
						this.create_visit_error = true
					}
				})
				.catch(err => {
					console.log(err)
				})
		},
		async day_select(d)
		{
			this.create_visit_error = false
			this.create_visit_ok = false
			if(d==this.day){
				this.day=0
				this.time=0
				this.service_list=null
				this.service=0
				this.patient_list=null
				this.patient=0
				return
			}
			this.day=d
			axios({url: config.API_LOCATION+'lk/schedule/staff_time_list/'+this.staff_id+'/'+this.day+'/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.time_list=resp.data.data
						this.create_visit_button_active=true
						if(!!this.site_t)
							this.time_select(this.site_t)
					} else
						this.create_visit_error = true
				})
				.catch(err => {
					console.log(err)
				})
		},
		async time_select(t)
		{
			this.create_visit_error = false
			this.create_visit_ok = false
			if(t==this.time){
				this.time=0
				this.service_list=null
				this.service=0
				this.patient_list=null
				this.patient=0
				return
			}
			this.time=t
			this.service_list=null
			this.service=0
			axios({url: config.API_LOCATION+'lk/schedule/staff_service_list/'+this.staff_id+'/'+this.time+'/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.service_list = resp.data.data
						this.service = this.service_list[Object.keys(this.service_list)[0]].id

						this.site_staff=''
						this.site_d=''
						this.site_t=''
						localStorage.removeItem('schedule_site_staff')
						localStorage.removeItem('schedule_site_d')
						localStorage.removeItem('schedule_site_t')
					} else
						this.create_visit_error = true
				})
				.catch(err => {
					console.log(err)
				})
			axios({url: config.API_LOCATION+'lk/schedule/patient_list/' })
				.then(resp => {
					if(resp.data.status=='ok') {
						if(Object. keys(resp.data.data). length >0) {
							this.patient_list = resp.data.data
							this.patient = this.patient_list[Object.keys(this.patient_list)[0]].id
						}
						else{
							this.patient_list=null
							this.patient=0
						}
					} else
						this.create_visit_error=true
				})
				.catch(err => {
					console.log(err)
				})
		},
		async create_visit()
		{
			this.create_visit_error = false
			this.create_visit_ok = false
			this.create_visit_button_active=false
			axios({url: config.API_LOCATION+'lk/schedule/create_visit/' , data: {staff_id:this.staff_id,from:this.time,service_id:this.service,patient_id:this.patient} , method: 'POST' })
				.then(resp => {
					if(resp.data.status=='ok') {
						this.create_visit_error = false
						this.create_visit_ok = true
					} else {
						this.create_visit_error = true
						this.create_visit_ok = false
					}
				})
				.catch(err => {
					console.log(err)
				})
		}
	},
}
</script>