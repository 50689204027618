import { createApp } from 'vue'
import App from './App'
//import components from '@/components/UI';
import router from "@/router/router";
import store from '@/store';
import axios from 'axios'
import VueAxios from 'vue-axios'

const token = localStorage.getItem('token')
if (token) {
	axios.defaults.headers.common['Authorization'] = token
}
axios.interceptors.response.use(function (resp) {
	if(resp.data.status=='error'&&resp.data.error_code=='no_auth') {
		store.dispatch('logout')
			.then(() => {
				router.push('/login')
			})
	}
	return resp;
});

import libStyles from './scss/libs.scss';
import themeStyles from './scss/theme.scss';

const app = createApp(App);

//Object.entries(components).forEach(([key, value]) => app.component(value.name, value) );



//components.forEach(
//	component => {
//	app.component(component.name, component)
//}
//);

app
	.use(router)
	.use(VueAxios, axios)
	.use(store)
	.mount('#app');
