<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">
                <p class="text-center">
                    <img src="@/img/logo.svg" style="width: 40%;">
                </p>
                <!-- Heading -->
                <h1 class="display-4 text-center mb-3">
                    Личный кабинет
                </h1>

                <!-- Subheading -->
                <p class="text-muted text-center mb-4">
                    Получение доступа в личный кабинет клиента
                </p>

                <div class="alert alert-warning fade show" role="alert">
                    Если вы уже были в нашей клинике и являетесь нашим клиентом, введите номер телефона и дату рождения лица, на которое оформлен договор.
                </div>

                <!-- Form -->


                <!-- Email address -->
                <div class="form-group">

                    <!-- Label -->
                    <label class="form-label">
                        Номер телефона
                    </label>

                    <!-- Input -->
                    <input type="tel" v-model="phone" class="form-control" placeholder="+7 (999) 888-77-66" data-inputmask="'mask': '+7 (999) 999-99-99'">

                </div>

                <div class="form-group">

                    <!-- Label -->
                    <label class="form-label">
                        Дата рождения
                    </label>

                    <!-- Input -->
                    <input type="text" v-model="birthday" class="form-control" placeholder="01.01.1990" data-inputmask="'mask': '99.99.9999'">
                    <div v-if="error_1" class="badge bg-danger-soft">
                        {{ error_text_1 }}
                    </div>

                </div>

                <!-- Password -->
                <div class="form-group">
                    <div class="row">
                        <div class="col">

                            <!-- Label -->
                            <label v-if="sms_sent" class="form-label">
                                Код
                            </label>


                        </div>

                    </div> <!-- / .row -->

                    <!-- Input group -->
                    <div v-if="sms_sent" class="input-group input-group-merge">

                        <!-- Input -->
                        <input v-model="code" class="form-control" type="text" placeholder="Введите код из смс">

                    </div>
                    <div v-if="error_2" class="badge bg-danger-soft">
                        {{ error_text_2 }}
                    </div>
                    <div v-if="timer > 0">
                        <label class="form-label">
                            Повторно запросить можно через {{ timer_text }}
                        </label>
                    </div>
                    <a v-if="sms_sent && timer == 0" @click="send_sms" class="form-text small text-muted">Выслать код повторно</a>
                    <div v-if="sms_sent" class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" v-model="not_save">
                        <label class="form-check-label" for="switchOne"><small class="form-text text-muted">Не запоминать</small></label>
                    </div>
                </div>

                <!-- Submit -->
                <button v-if="!sms_sent" @click="send_sms" :disabled="timer > 0" class="btn btn-lg w-100 btn-primary mb-3">Получить код</button>
                <button v-if="sms_sent" @click="login" class="btn btn-lg w-100 btn-primary mb-3">Войти</button>

                <!-- Link -->
                <p class="text-center">
                    <a @click="$router.push('/login')" class="form-text small text-muted">
                        Страница входа по паролю
                    </a>
                </p>





            </div>
            <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">

                <!-- Image -->
                <div class="bg-cover h-100 min-vh-100 mt-n1 me-n3" :style="ccsBackgroundImage"></div>

            </div>
        </div> <!-- / .row -->
    </div>

</template>
<script>
import store from '@/store';
import axios from 'axios';
import config from '@/config.js'
import Inputmask from 'inputmask'


export default {
    components: {

    },
    store,
    data() {
        return {
            ccsBackgroundImage: {
                backgroundImage: `url(${require('@/img/dd/auth-side-cover-med.jpg')})`
            },
            phone: '',
            code: '',
            birthday: '',
            not_save: false,
            sms_sent: false,
            timer: 0,
            timer_text: '',
            timer_intervalid: 0,
            token_tmp: localStorage.getItem('token_tmp') || '',
            error_1: false,
            error_text_1: '',
            error_2: false,
            error_text_2: ''
        }
    },
    methods: {
        send_sms: function () {
            axios({ url: config.API_LOCATION + 'lk/login/send_sms/', data: { login: this.phone, birthday: this.birthday }, method: 'POST' })
                .then(resp => {
                    console.log(resp)
                    if (resp.data.status == 'error') {
                        this.error_1 = true
                        if (resp.data.error_code == 'not_found')
                            this.error_text_1 = 'Ваши данные не найдены, обратитесь в администраторам центра'
                        if (resp.data.error_code == 'sms_limit') {
                            this.error_text_1 = 'Превышен лимит на отправку sms'
                            this.sms_sent = true
                            this.set_time(resp.data.sms_limit_timer)
                        }
                        if (resp.data.error_code == 'sms_send_error') {
                            this.error_text_1 = 'Не возможно отправить sms, обратитесь к администраторам центра'
                        }
                    }
                    else {
                        this.error_1 = false
                        this.error_2 = false
                        this.set_time(120)
                        this.sms_sent = true
                        localStorage.setItem('token_tmp', resp.data.token)
                        this.token_tmp = resp.data.token
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        set_time: function (timer) {
            this.timer = timer
            this.timer_text = new Date(this.timer * 1000).toISOString().substr(14, 5);
            this.timer_intervalid = setInterval(function () {
                this.timer -= 1
                if (this.timer == 0) {
                    clearInterval(this.timer_intervalid)
                    this.timer_intervalid = 0
                }
                else
                    this.timer_text = new Date(this.timer * 1000).toISOString().substr(14, 5);
            }.bind(this), 1000);
        },
        login: function () {
            let login = this.phone
            let code = this.code
            let token = this.token_tmp
            let not_save = this.not_save
            this.$store.dispatch('login_sms', { login, code, token, not_save })
                .then(function () {
                    localStorage.removeItem('token_tmp')
                    this.token_tmp = ''
                    if (this.timer_intervalid != 0)
                        clearInterval(this.timer_intervalid)
                    this.$router.push('/')
                }.bind(this))
                .catch(function (err) {
                    if (err.error_code == 'error_login') {
                        this.error_1 = true
                        this.error_text_1 = 'Ваши данные не найдены, обратитесь в администраторам центра'
                    }
                    if (err.error_code == 'error_code') {
                        this.error_2 = true
                        this.error_text_2 = 'Неверный код'
                    }
                }.bind(this))
        },
    },
    mounted() {
        const toggles = document.querySelectorAll('[data-inputmask]');
        const options = {
            rightAlign: false,
        };
        Inputmask().mask(toggles);
    }

}
</script>
<style>
</style>