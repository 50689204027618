<template>
	<div class="modal fade" id="modalMembers" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-card card" data-list="{&quot;valueNames&quot;: [&quot;name&quot;]}">
					<div class="card-header">

						<!-- Title -->
						<h4 class="card-header-title" id="exampleModalCenterTitle">
							Удалить запись?
						</h4>

						<!-- Close -->
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

					</div>
					<div v-if="delete_key!='0'" class="card-body">

						<!-- List group -->
						<div   class="card mb-3">
							<div class="card-body">
								<div class="row align-items-center">
									<div class="col-auto">

										<!-- Avatar -->
										<a  class="avatar avatar-lg">
											<template v-if="visit_list[delete_key].staff_photo!=''">
												<img :src="'https://api.2802801.ru/upload/resize.php?width=200&heigth=200&src=/upload/files/'+visit_list[delete_key].staff_photo" alt="..." class="avatar-img rounded-circle">
											</template>
											<template v-else>
												<img v-if="visit_list[delete_key].staff_sex=='M'" src="@/img/dd/m.jpg" class="avatar-img rounded-circle border border-4 border-card" alt="...">
												<img v-if="visit_list[delete_key].staff_sex=='F'" src="@/img/dd/w.jpg" class="avatar-img rounded-circle border border-4 border-card" alt="...">
											</template>
										</a>

									</div>
									<div class="col ms-n2">

										<!-- Title -->
										<h4 class="mb-1">
											{{visit_list[delete_key].staff_fio}}
										</h4>

										<!-- Text -->
										<p class="card-text small text-muted mb-1">
											{{visit_list[delete_key].staff_special}}
										</p>
										<p class="card-text small text-muted mb-1">
											{{visit_list[delete_key].time_title}}
										</p>
										<p class="card-text small text-muted mb-1">
											{{visit_list[delete_key].unit}}
										</p>
										<p v-if="visit_list[delete_key].patient_fio!=''" class="card-text small text-muted mb-1">
											Пациент: {{visit_list[delete_key].patient_fio}}
										</p>
									</div>

								</div> <!-- / .row -->
							</div> <!-- / .card-body -->
						</div>

						<div v-if="visit_list[delete_key].can_delete==1" class="btn-group mt-4">
							<button @click="delete_visit()" type="button" class="btn btn-secondary" ><span class="fe fe-delete mr-3"></span> Да</button>

						</div>
						<div v-if="visit_list[delete_key].can_delete==1" class="btn-group mt-4" style="width: 15px;">

						</div>
						<div v-if="visit_list[delete_key].can_delete==1" class="btn-group mt-4 ">
							<button data-bs-dismiss="modal" type="button" class="btn btn-secondary" ><span class="fe fe-save mr-3"></span> Нет</button>

						</div>
						<div v-if="visit_list[delete_key].can_delete==0" class="alert alert-warning" role="alert">

							<p class="mb-0">Автоматическая отмена не возможна. Позвоните по номеру телефона 280-280-1.</p>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<lg-menu/>

	<div class="main-content">
		<div class="container">
		<!-- HEADER -->
		<div class="header">
			<div class="container-fluid">

				<!-- Body -->
				<div class="header-body">
					<div class="row align-items-end">
						<div class="col">

							<!-- Pretitle -->
							<h6 class="header-pretitle">
								Личный кабинет
							</h6>

							<!-- Title -->
							<h1 class="header-title">
								Мои записи
							</h1>

						</div>

					</div> <!-- / .row -->
				</div> <!-- / .header-body -->
			</div>
		</div>

		<div class="container-fluid">
			<div class="row">
				<div class="col-12 col-xl-8">
					<div class="alert alert-success" role="alert">

						<p class="mb-0">Если по какой-либо причине Вы не можете  сопровождать Вашего ребенка на прием к врачу  в наш медицинский центр, оформите,  пожалуйста, <a href="https://xn--d1aacvtbold.xn--p1ai/upload/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%20%D0%BF%D0%BE%D1%80%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F%20%D0%94%D0%95%D0%A2%D0%A1%D0%9A%D0%98%D0%99%20%D0%94%D0%9E%D0%9A%D0%A2%D0%9E%D0%A0.pdf" target="_blank">договор поручения</a>.</p>

					</div>
					<template v-for="visit in visit_list" >
						<!-- Card -->
						<div  class="card mb-3">
							<div class="card-body">
								<div class="row align-items-center">
									<div class="col-auto">

										<!-- Avatar -->
										<a  class="avatar avatar-lg">
											<template v-if="visit.staff_photo!=''">
												<img :src="'https://api.2802801.ru/upload/resize.php?width=200&heigth=200&src=/upload/files/'+visit.staff_photo" alt="..." class="avatar-img rounded-circle">
											</template>
											<template v-else>
												<img v-if="visit.staff_sex=='M'" src="@/img/dd/m.jpg" class="avatar-img rounded-circle border border-4 border-card" alt="...">
												<img v-if="visit.staff_sex=='F'" src="@/img/dd/w.jpg" class="avatar-img rounded-circle border border-4 border-card" alt="...">
											</template>
										</a>

									</div>
									<div class="col ms-n2">

										<!-- Title -->
										<h4 class="mb-1">
											{{visit.staff_fio}}
										</h4>

										<!-- Text -->
										<p class="card-text small text-muted mb-1">
											{{visit.staff_special}}
										</p>
										<p class="card-text small text-muted mb-1">
											{{visit.time_title}}
										</p>
										<p class="card-text small text-muted mb-1">
											{{visit.unit}}
										</p>
										<p v-if="visit.patient_fio!=''" class="card-text small text-muted mb-1">
											Пациент: {{visit.patient_fio}}
										</p>
									</div>
									<div class="row" style="position: center;">

										<!-- Button -->
										<a @click="delete_confirm(visit.id)"  class="btn btn-sm   d-md-inline-block bg-dark-soft" style="width: 200px; position: center;">
											Отменить запись
										</a>

									</div>
								</div> <!-- / .row -->
							</div> <!-- / .card-body -->
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
	</div>
</template>
<script>
import axios from 'axios';
import config from '@/config.js'
import LgMenu from '@/components/Menu.vue'
import { Modal } from 'bootstrap';

export default {
	components: {
		LgMenu
	},
	data() {
		return {
			visit_list: null,
			delete_id: 0,
			delete_key: '0',
			delete_confirm_modal: null,
		}
	},
	mounted()
	{
		this.load()
		this.delete_confirm_modal = new Modal(document.getElementById('modalMembers'))
	},
	methods: {
		async load()
		{
			axios({url: config.API_LOCATION + 'lk/schedule/visit_list/'})
				.then(resp => {
					this.visit_list = resp.data.data
				})
				.catch(err => {
					console.log(err)
				})
		},
		delete_confirm(id)
		{
			this.delete_id=id;
			this.delete_key='id_'+id;
			this.delete_confirm_modal.show()
		},
		delete_visit()
		{
			axios({url: config.API_LOCATION + 'lk/schedule/visit_delete/'+this.delete_id+'/'})
				.then(resp => {
					this.delete_id=0;
					this.delete_key='0';
					this.visit_list=null;
					this.delete_confirm_modal.hide();
					this.load();
				})
				.catch(err => {
					console.log(err)
				})
		}
	}
}
</script>
