import {createRouter, createWebHistory} from "vue-router";
import store from '@/store';
import Index from "@/pages/Index";
import Login from "@/pages/login/Login";
import LoginCode from "@/pages/login/Code";
import Registration from "@/pages/login/Registration";
import ChangePassword from "@/pages/ChangePassword";
import Schedule from "@/pages/Schedule";
import ScheduleAdd from "@/pages/ScheduleAdd";
import ScheduleAddSpecial from "@/pages/ScheduleAddSpecial";
import ScheduleSite from "@/pages/ScheduleSite";
import Visit from "@/pages/Visit";

const routes = [
	{
		path: '/',
		component: Index
	},
	{
		path: '/login',
		component: Login,
		meta: {
			requiresAuth: false
		}
	},
	{
		path: '/login/code',
		component: LoginCode,
		meta: {
			requiresAuth: false
		}
	},
	{
		path: '/login/registration',
		component: Registration,
		meta: {
			requiresAuth: false
		}
	},
	{
		path: '/change-password',
		component: ChangePassword
	},
	{
		path: '/schedule',
		component: Schedule
	},
	{
		path: '/schedule/new',
		component: ScheduleAdd
	},
	{
		path: '/schedule/new-special',
		component: ScheduleAddSpecial
	},
	{
		path: '/schedule-site',
		component: ScheduleSite,
		meta: {
			requiresAuth: false,
			onlyGuest: false
		}
	},
	{
		path: '/visit',
		component: Visit
	},
]

const router = createRouter({
	routes,
	history: createWebHistory(process.env.BASE_URL)
})

router.beforeEach((to, from, next) => {
	if(to.matched[0].meta.requiresAuth!=false) {
		if (store.getters.isLoggedIn) {
			next()
			return
		}
		next('/login')
	} else {
		if (store.getters.isLoggedIn) {
			if(to.matched[0].meta.onlyGuest!=false) {
				next('/')
				return
			}
		}
		next()
	}
})

export default router;
