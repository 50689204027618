<template>

</template>
<script>
import axios from 'axios';
import config from '@/config.js'
import router from "../router/router";
export default {

	mounted ()
	{
		localStorage.setItem('schedule_site_staff', this.$route.query.staff)
		localStorage.setItem('schedule_site_d', this.$route.query.d)
		localStorage.setItem('schedule_site_t', this.$route.query.t)

		axios({url: config.API_LOCATION+'lk/schedule/log_site_hit/'+this.$route.query.staff +'/'+this.$route.query.t+'/'})
			.then(resp => {
				router.push('/schedule/new')
			})
			.catch(err => {
				console.log(err)
			})
	}
}
</script>