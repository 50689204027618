<template>
	<lg-menu/>

	<div class="main-content">




		<!-- HEADER -->
		<div class="header">
			<div class="container-fluid">

				<!-- Body -->
				<div class="header-body">
					<div class="row align-items-end">
						<div class="col">

							<!-- Pretitle -->
							<h6 class="header-pretitle">
								Рабочий стол
							</h6>

							<!-- Title -->
							<h1 class="header-title">
								Смена пароля
							</h1>

						</div>

					</div> <!-- / .row -->
				</div> <!-- / .header-body -->
			</div>
		</div>

		<div class="container-lg">
			<div class="row justify-content-center">
				<div class="col-12 col-lg-10 col-xl-8">

					<!-- Form -->
					<form class="tab-content py-6" id="wizardSteps">
						<div class="tab-pane fade show active" id="wizardStepOne" role="tabpanel" aria-labelledby="wizardTabOne">

							<!-- Header -->
							<div class="row justify-content-center">
								<div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">

									<!-- Pretitle -->


									<!-- Subtitle -->
									<p class="mb-5 text-muted">
										Задайте новый пароль
									</p>

								</div>
							</div> <!-- / .row -->

							<!-- Team name -->
							<div class="form-group">



								<!-- Input -->
								<input v-model="password1" class="form-control" type="password" placeholder="Введите новый пароль">

							</div>
							<div class="form-group">



								<!-- Input -->
								<input v-model="password2" class="form-control" type="password" placeholder="Введите новый пароль еще раз">
								<div v-if="error_1" class="badge bg-danger-soft">
									{{error_text_1}}
								</div>

							</div>

							<!-- Footer -->


							<div v-if="sms_sent" class="input-group input-group-merge">

								<!-- Input -->
								<input v-model="code" class="form-control" type="text" placeholder="Введите код из смс" >

							</div>
							<div v-if="error_2" class="badge bg-danger-soft">
								{{error_text_2}}
							</div>
							<div v-if="sms_sent&&timer>0">
								<label class="form-label">
									Повторно запросить можно через {{timer_text}}
								</label>
							</div>
							<a v-if="sms_sent&&timer==0" @click="send_sms" class="form-text small text-muted">Выслать код повторно</a>

							<div class="nav row align-items-center">

								<div class="col-auto">

									<!-- Button -->
									<a v-if="!sms_sent" @click="send_sms" class="btn btn-lg btn-primary" data-toggle="wizard" >Сменить</a>
									<a v-if="sms_sent" @click="change" class="btn btn-lg btn-primary" data-toggle="wizard" >Сменить</a>

								</div>
							</div>
							<span v-if="ok" class="badge bg-success-soft">Пароль успешно сменен</span>

						</div>
					</form>
				</div>
			</div>
		</div>


	</div>
</template>
<script>
import LgMenu from '@/components/Menu.vue'
import axios from 'axios';
import config from '@/config.js'
	export default {
		components: {
			LgMenu
		},
		data() {
			return {
				password1 : '',
				password2 : '',
				sms_sent: false,
				timer : 0,
				timer_text : '',
				timer_intervalid : 0,
				error_1 : false,
				error_text_1 : '',
				error_2 : false,
				error_text_2 : '',
				ok: false
			}
		},
		methods: {
			send_sms: function ()
			{
				this.ok=false
				if(this.password1.length<7){
					this.error_1=true
					this.error_text_1='Минимальная длина пароля 7 символов'
					return
				}
				if(this.password1!=this.password2){
					this.error_1=true
					this.error_text_1='Пароли не совпадают'
					return
				}
				axios({url: config.API_LOCATION+'lk/login/change_password/' })
					.then(resp => {
						console.log(resp)
						if(resp.data.status=='error'){
							this.error_1=true
							if(resp.data.error_code=='sms_limit') {
								this.error_text_1 = 'Превышен лимит на отправку sms'
								this.sms_sent = true
								this.set_time(resp.data.sms_limit_timer)
							}
							if(resp.data.error_code=='sms_send_error') {
								this.error_text_1 = 'Не возможно отправить sms, обратитесь к администраторам центра'
							}
						}
						if(resp.data.status=='ok'){
							this.error_1=false
							this.error_2=false
							this.set_time(120)
							this.sms_sent = true
						}
					})
					.catch(err => {
						console.log(err)
					})
			},
			set_time: function (timer)
			{
				this.timer=timer
				this.timer_text=new Date(this.timer * 1000).toISOString().substr(14, 5);
				this.timer_intervalid = setInterval (function(){
					this.timer-=1
					if(this.timer==0) {
						clearInterval(this.timer_intervalid)
						this.timer_intervalid=0
					}
					else
						this.timer_text=new Date(this.timer * 1000).toISOString().substr(14, 5);
				}.bind(this), 1000);
			},
			change: function ()
			{
				if(this.password1.length<7){
					this.error_1=true
					this.error_text_1='Минимальная длина пароля 7 символов'
					return
				}
				if(this.password1!=this.password2){
					this.error_1=true
					this.error_text_1='Пароли не совпадают'
					return
				}
				axios({url: config.API_LOCATION+'lk/login/change_password_code/' , data: {password:this.password1,code:this.code} , method: 'POST' })
					.then(resp => {
//						console.log(resp)
						if(resp.data.status=='error'){
							if(resp.data.error_code=='error_code') {
								this.error_2 = true
								this.error_text_2 = 'Неверный код'
							}
						}
						if(resp.data.status=='ok'){
							this.error_1=false
							this.error_2=false
							this.ok=true
							this.sms_sent=false
							this.password1=''
							this.password2=''
						}
					})
					.catch(err => {
						console.log(err)
					})
			},
		}
	}
</script>
<style>

</style>