<template>





		<router-view></router-view>



</template>

<script>

export default {
	components: {

	},
	data() {
		return {

		}
	},
	methods: {

	}
}
</script>

<style>

</style>