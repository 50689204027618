import {createStore} from "vuex";
import axios from 'axios';
import config from '@/config.js'


export default createStore({
	state: {
		status: '',
		token: localStorage.getItem('token') || '',
		user : {}
	},
	mutations: {
		auth_request(state){
			state.status = 'loading'
		},
		auth_success(state, token, user){
			state.status = 'success'
			state.token = token
			state.user = user
		},
		auth_error(state){
			state.status = 'error'
		},
		logout(state){
			state.status = ''
			state.token = ''
		},
	},
	actions: {
		login({commit}, user)
		{
			return new Promise((resolve, reject) => {
				commit('auth_request')
				axios({url: config.API_LOCATION+'lk/login/login/', data: user, method: 'POST' })
					.then(resp => {
						if(resp.data.status=='ok') {
							const token = resp.data.token
							const user = resp.data.user
							localStorage.setItem('token', token)
							axios.defaults.headers.common['Authorization'] = token
							commit('auth_success', token, user)
							resolve(resp)
						}
						else{
							commit('auth_error')
							localStorage.removeItem('token')
							delete axios.defaults.headers.common['Authorization']
							reject({error_code:resp.data.error_code})
						}
					})
					.catch(err => {
						commit('auth_error')
						localStorage.removeItem('token')
						delete axios.defaults.headers.common['Authorization']
						reject({error_code:resp.data.error_code})
					})
			})
		},
		login_sms({commit}, user)
		{
			return new Promise((resolve, reject) => {
				commit('auth_request')
				axios({url: config.API_LOCATION+'lk/login/login_sms/', data: user, method: 'POST' })
					.then(resp => {
						if(resp.data.status=='ok') {
							const token = resp.data.token
							const user = resp.data.user
							localStorage.setItem('token', token)
							axios.defaults.headers.common['Authorization'] = token
							commit('auth_success', token, user)
							resolve(resp)
						}
						else{
							commit('auth_error')
							localStorage.removeItem('token')
							delete axios.defaults.headers.common['Authorization']
							reject({error_code:resp.data.error_code})
						}
					})
					.catch(err => {
						commit('auth_error')
						localStorage.removeItem('token')
						reject(err)
					})
			})
		},
		registration_sms({commit}, user)
		{
			return new Promise((resolve, reject) => {
				commit('auth_request')
				axios({url: config.API_LOCATION+'lk/login/registration_sms/', data: user, method: 'POST' })
					.then(resp => {
						if(resp.data.status=='ok') {
							const token = resp.data.token
							const user = resp.data.user
							localStorage.setItem('token', token)
							axios.defaults.headers.common['Authorization'] = token
							commit('auth_success', token, user)
							resolve(resp)
						}
						else{
							commit('auth_error')
							localStorage.removeItem('token')
							delete axios.defaults.headers.common['Authorization']
							reject({error_code:resp.data.error_code})
						}
					})
					.catch(err => {
						commit('auth_error')
						localStorage.removeItem('token')
						reject(err)
					})
			})
		},
		logout({commit})
		{
			return new Promise((resolve, reject) => {
				commit('logout')
				localStorage.removeItem('token')
				delete axios.defaults.headers.common['Authorization']
				resolve()
			})
		}
	},
	getters : {
		isLoggedIn: state => !!state.token,
		authStatus: state => state.status,
	}

})
